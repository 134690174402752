import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ILang } from "src/app/model/entities/lang.interface";
import { IWords } from "src/app/model/entities/words.interface";
import { Timeout } from "../../decorators/timeout";
import { AppService } from "../../services/app.service";
import { AuthService } from "../../services/auth.service";
import { cfg } from "src/app/config";
import { IUfiles } from "src/app/model/entities/ufile.interface";

@Component({
    selector: "the-header",
    templateUrl: "header.component.html",
    styleUrls: ["header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private appService: AppService,
        private authService: AuthService,
        private router: Router,
    ) {}

    get langs(): ILang[] {return this.appService.langs;}
    get lang(): ILang {return this.appService.lang;}
    get words(): IWords {return this.appService.words;}
    get url(): string[] {return this.appService.url;}
    get authenticated(): boolean {return this.authService.authData !== null;}
    get staticUrl(): string {return cfg.staticUrl;}
    get ufiles(): IUfiles {return this.appService.ufiles;}

    public ngOnInit(): void {
        this.onScroll = this.onScroll.bind(this);
    }

    @Timeout(1)
    public ngAfterViewInit(): void {
        this.appService.win.addEventListener("scroll", this.onScroll)
    }

    public ngOnDestroy(): void {
        this.appService.win.removeEventListener("scroll", this.onScroll)
    }

    // layout
    public opaque: boolean = false;

    private onScroll(): void {
        this.opaque = this.appService.win.scrollTop > 0;
    }

    // langs menu
    public getLangLink(lang: ILang): string {
        return this.appService.getLangLink(lang);
    }

    public getLangImg(lang: ILang): string {
        return `${this.staticUrl}/images/langs/${lang.img}`;
    }

    // main menu
    public async onMenuClick(blockName: string): Promise<void> {
        if (this.url[2]) {
            this.router.navigateByUrl(`/${this.lang.slug}`);
            await this.appService.pause(10); // wait for page initial autoscroll
        }

        this.appService.scrollTo(blockName);
    }

    public async onPaymentClick() {
        this.router.navigateByUrl(`/${this.lang.slug}`);
        await this.appService.pause(10); // wait for page initial autoscroll

        this.appService.scrollTo('payment');
    }

    // auth popups
    set pRegisterActive(v: boolean) {this.appService.pRegisterActive = v;}
    set pLoginActive(v: boolean) {this.appService.pLoginActive = v;}
    get pLogoutActive(): boolean {return this.appService.pLogoutActive;}
    set pLogoutActive(v: boolean) {this.appService.pLogoutActive = v;}
    get pAccountActive(): boolean {return this.appService.pAccountActive;}
    set pAccountActive(v: boolean) {this.appService.pAccountActive = v;}
    get paNotificationsActive(): boolean {return this.appService.paNotificationsActive;}
    set paNotificationsActive(v: boolean) {this.appService.paNotificationsActive = v;}

    // mobile menus
    public mmMainActive: boolean = false;
    public mmAccountActive: boolean = false;

    public async toggleMMMain(): Promise<void> {
        if (this.mmMainActive) {
            this.mmMainActive = false;
        } else {
            this.mmAccountActive = false;
            await this.appService.pause(1);
            this.mmMainActive = true;
        }
    }

    public async toggleMMAccount(): Promise<void> {
        if (this.mmAccountActive) {
            this.mmAccountActive = false;
        } else {
            this.mmMainActive = false;
            await this.appService.pause(1);
            this.mmAccountActive = true;
        }
    }

    public downloadApp() {
        window.open(`${this.staticUrl}/ufiles/${this.ufiles?.['android-app'].fileurl}`);
    }

    // panels
    public pNotificationsActive = false;
}
