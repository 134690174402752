<header [class.opaque]="opaque || mmMainActive || mmAccountActive">
    <div class="h-content-desktop">
        <div class="hd-left">
            <div class="h-logo">
                <div class="h-logo"><a routerLink="/{{lang.slug}}"><img src="/assets/images/logo.png" alt="logo"></a></div>
            </div>
            <nav class="h-menu">
                <ul>
                    <li><a (click)="onMenuClick('about')">{{words['header']?.['about']?.[lang.slug]}}</a></li>
                    <li><a (click)="onMenuClick('calculator')">{{words['header']?.['calculator']?.[lang.slug]}}</a></li>
                    <li><a (click)="onMenuClick('crypto')">{{words['header']?.['crypto']?.[lang.slug]}}</a></li>
                </ul>
            </nav>
        </div>
        <div class="h-account">
            <ng-container *ngIf="authenticated">
                <div class="ha-btns">
                    <lang-dropdown></lang-dropdown>
                    <button class="btn ha-btn" (click)="downloadApp()"><span>{{words['header']?.['download']?.[lang.slug]}}</span></button>
                    <button class="btn outline ha-btn-payment" (click)="onPaymentClick()"><span>{{words['header']?.['check-payment']?.[lang.slug]}}</span></button>
                    <div class="ha-links">
                        <div class="smallbtn account" (click)="pAccountActive = !pAccountActive" [class.active]="pAccountActive"></div>
                        <panel-account [(active)]="pAccountActive"></panel-account>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!authenticated">
                <div class="ha-btns">
                    <lang-dropdown></lang-dropdown>
                    <button class="btn outline ha-btn-payment" (click)="onPaymentClick()"><span>{{words['header']?.['check-payment']?.[lang.slug]}}</span></button>
                    <button class="btn ha-btn" (click)="downloadApp()"><span>{{words['header']?.['download']?.[lang.slug]}}</span></button>
                    <button class="btn ha-btn" (click)="pLoginActive = true"><span>{{words['header']?.['login']?.[lang.slug]}}</span></button>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="h-content-mobile" [class.mm-active]="mmMainActive" >
        <div class="h-content-container">
            <div class="h-logo"><a routerLink="/{{lang.slug}}"><img src="/assets/images/logo.png" alt="logo"></a></div>
        </div>

        <div class="h-account">
            <ng-container *ngIf="authenticated">
                <div class="ha-links">
                    <button class="btn ha-btn" style="margin-right: 16px;" (click)="downloadApp()"><span>{{words['header']?.['download']?.[lang.slug]}}</span></button>
                    <div class="smallbtn mob-account" (click)="mmAccountActive = !mmAccountActive" [class.close]="mmAccountActive" [class.account]="!mmAccountActive"></div>
                    <panel-account [(active)]="pAccountActive"></panel-account>
                </div>
            </ng-container>
            <ng-container *ngIf="!authenticated">
                <button class="btn ha-btn" style="margin-right: 16px;" (click)="downloadApp()"><span>{{words['header']?.['download']?.[lang.slug]}}</span></button>
                <div class="smallbtn login" (click)="pLoginActive = true"></div>
                <div class="smallbtn" [class.menu]="!mmMainActive" [class.close]="mmMainActive" (click)="toggleMMMain()"></div>
            </ng-container>
        </div>
    </div>
    <mm-main [(active)]="mmMainActive" (menuClick)="onMenuClick($event)"></mm-main>
    <mm-account [(active)]="mmAccountActive"></mm-account>
</header>
